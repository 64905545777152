import React, { memo, useMemo } from "react"

import { useCore } from "../../../hooks/useCore"
import { useCustomerRecover } from "../../../hooks/useCustomer"

export const withAccountForgot = Component =>
  memo(({ name = "AccountForgot", page }: any) => {
    const {
      helpers: { sanityContent },
    } = useCore()
    const { recoverCustomer, data, setData, success, loading, errors } = useCustomerRecover()

    const content = sanityContent(page?.content)
    const successContent = sanityContent(page?.successContent)
    const successTitle = page?.successTitle

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          content={content}
          data={data}
          errors={errors}
          loading={loading}
          page={page}
          recoverCustomer={recoverCustomer}
          setData={setData}
          success={success}
          successContent={successContent}
          successTitle={successTitle}
        />
      ),
      [content, data, errors, loading, page, recoverCustomer, setData, success, successContent, successTitle]
    )
  })
