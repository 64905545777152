import React from "react"
import { graphql } from "gatsby"

import { withoutAuthentication } from "../../hoc/withoutAuthentication"
import { AccountForgot as Page } from "../../components/Account/Forgot/AccountForgot"

export const query = graphql`
  query {
    page: sanityPageAccountForgot {
      title
      successTitle
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      content: _rawContent(resolveReferences: { maxDepth: 2 })
      successContent: _rawSuccessContent(resolveReferences: { maxDepth: 2 })
      additionalEmail
      additionalSubmit
    }
    template: sanityTemplateAccount {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
    }
  }
`

const Component = withoutAuthentication(({ data, ...props }): JSX.Element => <Page {...props} {...data} />)

export default Component
