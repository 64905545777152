import React from "react"

import { withAccountForgot } from "./withAccountForgot"
import { Breadcrumbs } from "../../Breadcrumbs/Breadcrumbs"
import { Icon } from "../../Icon/Icon"
import { AccountForgotForm } from "./Form/AccountForgotForm"
import { StyledContainer } from "../../Styled/Container"
import { StyledRow } from "../../Styled/Row"
import { Page, Title, Content } from "./styledAccountForgot"

export const AccountForgot = withAccountForgot(
  ({ content, data, errors, loading, page, recoverCustomer, setData, success, successContent, successTitle }): JSX.Element => (
    <Page>
      <Breadcrumbs background={`orange-pastel`} colour={`dark`} page={page} />
      {success ? (
        <StyledContainer width={`lg`}>
          <StyledRow justify={`center`} withSpacing>
            <Icon colour={`green`} name={`tick`} width={96} />
          </StyledRow>
          <Title as={`h1`} align={`center`} withSpacing={`sm`}>
            {successTitle}
          </Title>
          <Content align={`center`} colour={`grey-darker`}>
            {successContent}
          </Content>
        </StyledContainer>
      ) : (
        <>
          <StyledContainer width={`lg`}>
            <Title as={`h1`} align={`center`} withSpacing={`sm`}>
              {page?.title}
            </Title>
          </StyledContainer>
          <StyledContainer width={`md`}>
            <Content align={`center`} colour={`grey-darker`} withSpacing={`lg`}>
              {content}
            </Content>
            <AccountForgotForm data={data} errors={errors} loading={loading} page={page} recoverCustomer={recoverCustomer} setData={setData} />
          </StyledContainer>
        </>
      )}
    </Page>
  )
)
