import React from "react"

import { withAccountForgotForm } from "./withAccountForgotForm"
import { FormInput } from "../../../Form/Input/FormInput"
import { Form, Button } from "./styledAccountForgotForm"

export const AccountForgotForm = withAccountForgotForm(
  ({ data, errors, handleChange, handleSubmit, loading, locales }): JSX.Element => (
    <Form onSubmit={handleSubmit}>
      <FormInput
        autoFocus
        name={`email`}
        type={`email`}
        errors={errors}
        full
        onChange={handleChange}
        placeholder={locales?.additionalEmail}
        required
        value={data?.email}
        withSpacing={`md`}
      />
      <Button type={`submit`} colour={`purple`} disabled={loading} size={`primary`} title={locales?.additionalSubmit} withSpacing>
        {locales?.additionalSubmit}
      </Button>
    </Form>
  )
)
