import React from "react"
import { navigate } from "gatsby"

import { useApp } from "../hooks/useApp"
import { useCore } from "../hooks/useCore"

export const withoutAuthentication =
  Component =>
  ({ ...props }) => {
    const {
      helpers: { isBrowser, storage },
    } = useCore()

    const {
      config: {
        settings: { keys, routes },
      },
    } = useApp()

    const customerTokens = storage.get(keys?.customer)

    if (customerTokens?.accessToken && new Date(customerTokens?.expiresAt) >= new Date()) {
      if (isBrowser) navigate(routes?.DASHBOARD, { replace: true })

      return null
    }

    return <Component {...props} />
  }
