import React, { memo, useCallback, useMemo } from "react"

export const withAccountForgotForm = Component =>
  memo(({ name = "AccountForgotForm", data, errors, loading, page, recoverCustomer, setData }: any) => {
    const handleSubmit = useCallback(
      async event => {
        event.preventDefault()
        await recoverCustomer(data?.email)
      },
      [data, recoverCustomer]
    )

    const handleChange = useCallback(
      ({ target: { type, name, value, checked } }) => {
        setData(prevState => ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        }))
      },
      [setData]
    )

    Component.displayName = name
    return useMemo(
      () => <Component data={data} errors={errors} handleChange={handleChange} handleSubmit={handleSubmit} loading={loading} locales={page} />,
      [data, errors, handleChange, handleSubmit, loading, page]
    )
  })
